'use client'
import type { TypeProviderLayout } from '@lib/utilities/provider-utilities'
import type { TypeProviderPublic } from '@lib/provider-types'
import ProviderCareCard from '@app/(main)/doctors/[slug]/_components/provider-care-provider-card'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import { useRef, useState } from 'react'
import { Button, Icon, Typography } from '@shc/ui'
import useAnalytics from '@hooks/use-analytics'

export interface ProviderCarePartnersProps {
  provider: TypeProviderPublic
  providerLayout: TypeProviderLayout
}

const ProviderCarePartners = ({ provider, providerLayout }: ProviderCarePartnersProps) => {
  const [_, setInit] = useState<boolean>()
  const { track } = useAnalytics()
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  return (
    <>
      <div className="pb-8">
        <Typography variant="h2">Care partners</Typography>
      </div>
      <div className="pb-7">
        You are welcome to book appointments with your doctor&apos;s care team.
      </div>
      {Array.isArray(provider.care_partners) && provider.care_partners.length > 3 ? (
        <div className="flex flex-row gap-x-3">
          {provider.care_partners.length !== 1 && (
            <div className="hidden lg:flex flex-row items-center justify-center">
              <Button
                color="transparent"
                size="sm"
                shape="circle"
                width="auto"
                aria-label="Previous Slide"
                ref={prevRef}
                className="swiperPrev cursor-pointer disabled:text-[#18808C66] text-primary-700"
                disabled={activeIndex === 0 ? true : false}>
                <Icon className="h-6" icon="chevron-left" />
              </Button>
            </div>
          )}
          <Swiper
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex)
              track({
                event: { name: 'component_scroll', data: {} },
                contexts: [
                  {
                    name: 'component',
                    data: {
                      component_text: 'ProviderCarePartners',
                    },
                  },
                ],
              })
            }}
            loop={false}
            watchOverflow={false}
            a11y={{
              enabled: true,
              firstSlideMessage: 'This is the first slide',
              lastSlideMessage: 'This is the last slide',
            }}
            keyboard={{
              enabled: true,
            }}
            modules={[Navigation, A11y]}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onInit={() => setInit(true)}
            spaceBetween={20}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
              },
              639: {
                slidesPerView: 2.3,
              },
              640: {
                slidesPerView: 2.3,
              },
              768: {
                slidesPerView: 3.2,
              },
              1024: {
                slidesPerView:
                  provider.care_partners.length > 2 ? 3 : provider.care_partners.length,
              },
              1280: {
                slidesPerView:
                  provider.care_partners.length > 2 ? 3 : provider.care_partners.length,
              },
            }}>
            {provider.care_partners?.map((carePartner) => {
              return (
                <SwiperSlide
                  key={carePartner.id}
                  className="pb-1 pt-14 w-full"
                  data-testid="provider-slides">
                  <a href={`/doctors/${carePartner.slug}`}>
                    <ProviderCareCard
                      careProvider={carePartner}
                      providerLayout={providerLayout}
                      className="h-full w-full"
                    />
                  </a>
                </SwiperSlide>
              )
            })}
          </Swiper>
          {provider.care_partners.length !== 1 && (
            <div className="hidden lg:flex flex-row items-center justify-center">
              <Button
                color="transparent"
                size="sm"
                shape="circle"
                width="auto"
                aria-label="Next Slide"
                ref={nextRef}
                className="swiperNext cursor-pointer disabled:text-[#18808C66] text-primary-700"
                disabled={provider.care_partners.length - 1 === activeIndex ? true : false}>
                <Icon className="h-6" icon="chevron-right" />
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-row gap-x-5 overflow-x-auto">
          {Array.isArray(provider.care_partners) &&
            provider.care_partners?.length < 4 &&
            provider.care_partners?.map((carePartner) => (
              <div key={carePartner.id} className="pb-1 pt-14">
                <a href={`/doctors/${carePartner.slug}`}>
                  <ProviderCareCard
                    careProvider={carePartner}
                    providerLayout={providerLayout}
                    className="h-full w-[244px]"
                  />
                </a>
              </div>
            ))}
        </div>
      )}
    </>
  )
}

export default ProviderCarePartners
