import type { TypeProviderPublic } from '@lib/provider-types'
import { Link, Rating } from '@shc/ui'

export interface ProviderRatingsProps {
  provider: TypeProviderPublic
  link?: string
}

const ProviderRatings = ({ provider, link }: ProviderRatingsProps) => {
  return (
    <>
      {provider.review_count && provider.review_count > 29 ? (
        <div className="flex flex-row items-center">
          {provider.review_rating && <Rating value={provider.review_rating} />}
          {link ? (
            <Link className="pl-4" noUnderline href={link} aria-label="Provider Ratings">
              {provider.review_count} ratings
            </Link>
          ) : (
            <div className="pl-4">{provider.review_count} ratings</div>
          )}
        </div>
      ) : (
        <Link noUnderline href="/doctors#doctor-ratings-reviews-determined">
          No ratings available
        </Link>
      )}
    </>
  )
}

export default ProviderRatings
