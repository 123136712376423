import { type ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'

import { aspectRatioMap } from '@lib/token-maps'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import { getIcon } from '@components/icons'
import type { TypeProviderPublic } from '@lib/provider-types'
import type { ContentfulImagesAPI, ImageSizes } from '@components/contentful-image'
import ContentfulImage from '@components/contentful-image'
import { Icon, Link } from '@shc/ui'

const extras = generateBaseMediaQWidths(4)
const focalPoint = 'Face'
let aspectRatioVal = aspectRatioMap['1:1']
const imageSizes: ImageSizes[] | undefined = extras?.map((value) => {
  return {
    mediaQuery: value.mediaQuery,
    params: {
      w: value.w,
      h: Math.ceil(value.w / aspectRatioVal),
      fit: 'fill',
      f: focalPoint?.toLowerCase() as Lowercase<NonNullable<ContentfulImagesAPI['f']>>,
    },
  }
})

export interface ProviderImageProps extends ComponentPropsWithoutRef<'div'> {
  provider: TypeProviderPublic
}

const ProviderImage = ({ provider, ...props }: ProviderImageProps) => {
  const hasVideo = !!provider.video_urls?.[0]
  const hasPhoto = !!provider.photo_url?.includes('images.ctfassets.net')
  // set photo variable to either url from API or the photo placeholder
  const providerPhotoUrl = hasPhoto
    ? provider.photo_url?.replace(/^https?:/i, '')
    : '//images.ctfassets.net/pxcfulgsd9e2/4vtfSBGmp3LQhKtvjw2JIC/d0f2094db56d33c832190f5e281156e5/Placeholder_image.png'

  return (
    <div {...props}>
      {hasVideo && (
        // IMAGE WITH VIDEO
        <Link noUnderline href="#provider-about">
          <div className="flex flex-col relative gap-2 justify-center">
            {/* provider image */}
            <div className="flex relative justify-center">
              <div className="h-[100px] w-[100px] flex items-center justify-center bg-gradient-to-r from-lightGradientBlue to-darkGradientBlue rounded-full relative lg:h-[150px] lg:w-[150px] ">
                <ContentfulImage
                  src={providerPhotoUrl}
                  imageSizes={imageSizes}
                  alt="Provider Image"
                  className={clsx(
                    'object-cover w-[90px] h-[90px] lg:w-[135px] lg:h-[135px] rounded-full border-[3px] lg:border-[8px] border-gray-50'
                  )}
                />
              </div>

              {/* video icon */}
              <div className="h-[28px] w-[28px] lg:h-[36px] lg:w-[36px] bg-primary rounded-full flex items-center justify-center absolute bottom-0 right-0">
                <Icon className="text-white h-[12px] md:h-[16px]" icon="play" />
              </div>
            </div>
            {/* link text */}
            <div className="flex justify-center font-bold">Watch&nbsp;video</div>
          </div>
        </Link>
      )}
      {!hasVideo && (
        // IMAGE WITH NO VIDEO
        <ContentfulImage
          src={providerPhotoUrl}
          imageSizes={imageSizes}
          alt="Provider Image"
          className={clsx('w-[90px] h-[90px] lg:w-[135px] lg:h-[135px] rounded-full object-cover')}
        />
      )}
    </div>
  )
}

export default ProviderImage
