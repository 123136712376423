'use client'
import { ProviderContext, type TProviderContext } from '@lib/analytics'
import type { ReactNode } from 'react'

export interface ProviderAnalyticsWrapperWrapperProps {
  readonly value: TProviderContext
  readonly children: ReactNode
}

export default function ProviderAnalyticsWrapper({
  value,
  children,
}: ProviderAnalyticsWrapperWrapperProps) {
  return <ProviderContext.Provider value={value}>{children}</ProviderContext.Provider>
}
