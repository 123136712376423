'use client'
import { doScroll } from '@hooks/useCustomScroll'
import { Pagination } from '@shc/ui'
import { useRouter } from 'next/navigation'
import React from 'react'
import type { PaginationProps } from '@shc/ui/dist/components/Pagination/Pagination'

type ClientPaginationProps = {
  slug: string
  articleTotal: number
  currentPageNumber: number
  scrollTo: string
  queryBy?: string
} & PaginationProps

function ClientPagination(props: Readonly<ClientPaginationProps>) {
  const {
    slug,
    articleTotal,
    currentPageNumber,
    scrollTo,
    queryBy = 'page',
    ...paginationProps
  } = props
  const router = useRouter()

  const onPageChange = (page: number) => {
    router.push(slug + `?${queryBy}=` + page, { scroll: false })
    const element = document.getElementById(scrollTo)
    if (element !== null)
      doScroll(element.getBoundingClientRect().top + window.scrollY - window.scrollOffSet)
  }

  return (
    <Pagination
      current={currentPageNumber}
      total={articleTotal}
      onChange={onPageChange}
      {...paginationProps}
    />
  )
}

export default ClientPagination
