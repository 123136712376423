'use client'
import { sortBy } from 'lodash'
import { useState } from 'react'

import type { TypeProviderLayout } from '@lib/utilities/provider-utilities'
import type { TypeProviderPublic } from '@lib/provider-types'
import Modal from '@components/modal'
import ProviderImage from '@components/provider-image'
import ProviderRatings from '@app/(main)/doctors/[slug]/_components/provider-ratings'
import ProviderInsuranceModal from '@app/(main)/doctors/[slug]/_components/provider-insurance-modal'
import { Icon, Link, Typography } from '@shc/ui'

export interface ProviderCardProps {
  provider: TypeProviderPublic
  providerLayout: TypeProviderLayout
}

const ProviderCard = ({ provider, providerLayout }: ProviderCardProps) => {
  const [isMedicalGroupModalOpen, setIsMedicalGroupModalOpen] = useState(false)
  const [isNpModalOpen, setIsNpModalOpen] = useState(false)
  const [isPaModalOpen, setIsPaModalOpen] = useState(false)
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false)
  const providerType = provider.degrees?.[0]?.name_generalized ?? ''

  // placing this into a var so that we can display it modularly/dynamically
  const providerInfo = (
    <div className="flex flex-col gap-x-4 gap-y-4">
      {/* PROVIDER TYPE */}
      {providerType.length > 0 && (
        <div>
          <span>{providerType}</span>
          {providerType.toLowerCase().includes('nurse practitioner') && (
            <Link
              noUnderline
              onClick={() => setIsNpModalOpen(true)}
              color="dark"
              aria-label={providerType}
              data-testid={providerType?.toLowerCase().replace(/\s+/g, '-') + '-modal-link'}>
              <Icon className="h-4 pl-2 text" icon="circle-info" />
            </Link>
          )}
          {providerType.toLowerCase().includes('physician assistant') && (
            <Link
              noUnderline
              onClick={() => setIsPaModalOpen(true)}
              color="dark"
              aria-label={providerType}
              data-testid={providerType?.toLowerCase().replace(/\s+/g, '-') + '-modal-link'}>
              <Icon className="h-4 pl-2 text" icon="circle-info" />
            </Link>
          )}
        </div>
      )}
      {/* SPECIALTIES */}
      {provider.specialties && provider.specialties.length > 0 && (
        <div>
          {sortBy(provider.specialties, ['specialty_name']).map(
            ({ specialty_id, specialty_name, is_board_certified }) => {
              return (
                <div key={specialty_id}>
                  {specialty_name} {is_board_certified ? <span>(board certified)</span> : null}
                </div>
              )
            }
          )}
        </div>
      )}
      {/* MEDICAL GROUPS & INSURANCE*/}
      {((Array.isArray(provider.medical_groups) && provider.medical_groups?.length > 0) ||
        (Array.isArray(provider.insurance_plans) && provider.insurance_plans?.length > 0)) && (
        <div className="flex flex-row flex-nowrap gap-x-8">
          {/* MEDICAL GROUPS */}
          {Array.isArray(provider.medical_groups) && provider.medical_groups.length > 0 && (
            <div className="flex flex-col flex-nowrap ">
              <div>
                <Typography variant="body-semibold">
                  Medical Group
                  <Link
                    noUnderline
                    onClick={() => setIsMedicalGroupModalOpen(true)}
                    color="dark"
                    aria-label="Medical Group"
                    data-testid="medical-group-modal-link">
                    <Icon className="h-4 pl-2 text" icon="circle-info" />
                  </Link>
                </Typography>
              </div>
              <div>
                {provider.medical_groups &&
                  sortBy(provider.medical_groups, ['name']).map(({ id, name }) => {
                    return (
                      <span key={id}>
                        {name} <br />
                      </span>
                    )
                  })}
              </div>
            </div>
          )}
          {/* INSURANCE */}
          {Array.isArray(provider.insurance_plans) &&
            provider.insurance_plans.length > 0 &&
            providerLayout !== 'Non-referable' && (
              <div className="flex-col">
                <div>
                  <Typography variant="body-semibold">Insurance</Typography>
                </div>
                <div>
                  {/* TODO: The modal for Insurance is another story */}
                  <Link
                    onClick={() => setIsInsuranceModalOpen(true)}
                    noUnderline
                    aria-label="Accepted Insurance Plans">
                    Accepted plans
                  </Link>
                </div>
              </div>
            )}
        </div>
      )}
    </div>
  )

  return (
    <>
      {/* VIEW (auto, sm, md)  */}
      <div className="lg:hidden flex flex-col gap-y-4">
        <div className="flex flex-row gap-x-5">
          <ProviderImage provider={provider} className="min-w-fit" />
          <div className="flex flex-col gap-y-1 min-w-0">
            <Typography variant="h2" as="h1" className="break-words">
              {provider.name_formatted_with_degrees}
            </Typography>

            <div className="items-center lg:items-stretch">
              <ProviderRatings provider={provider} link="#provider-ratings" />
            </div>
          </div>
        </div>
        {providerInfo}
      </div>

      {/* VIEW (lg and up)  */}
      <div className="hidden lg:flex flex-row gap-x-5 xl:gap-x-15">
        <ProviderImage provider={provider} className="min-w-fit" />
        <div className="flex flex-col gap-y-4 min-w-0">
          <div className="flex flex-col gap-y-1">
            <Typography variant="h2" as="h1" className="break-words">
              {provider.name_formatted_with_degrees}
            </Typography>
            <ProviderRatings provider={provider} link="#provider-ratings" />
          </div>
          {providerInfo}
        </div>
      </div>

      {/* MEDICAL GROUP MODAL */}
      <Modal
        size="sm"
        contentName="MedicalGroup"
        isOpen={isMedicalGroupModalOpen}
        setIsOpen={setIsMedicalGroupModalOpen}
        className="flex flex-col"
        max-width="420"
        data-testid="medical-group-modal">
        <Modal.Description className="text-center">
          <div>
            <Icon className="h-10 text-center text-primary pb-8" icon="circle-info" />
          </div>
          A medical group is a network of doctors contracted by health plans to provide patient
          care. To verify insurance coverage for specific medical groups, contact your health plan.
          <Link
            className="pl-2"
            href="/health-insurance/medical-groups.cfm/#hmo"
            aria-label="Learn more about medical groups">
            Learn more
          </Link>
          .
        </Modal.Description>
      </Modal>

      {/* PHYSICIAN ASSISTANT MODAL */}
      <Modal
        size="sm"
        contentName="PhysicianAssistant"
        isOpen={isPaModalOpen}
        setIsOpen={setIsPaModalOpen}
        className="flex flex-col"
        data-testid="physician-assistant-modal">
        <Modal.Description className="text-center">
          <div>
            <Icon className="h-10 text-center text-primary pb-8" icon="circle-info" />
          </div>
          Physician assistants are hightly trained medical providers who can diagnose, treat and
          manage your care while working with a doctor.
        </Modal.Description>
      </Modal>

      {/* NURSE PRACTIONER MODAL */}
      <Modal
        size="sm"
        contentName="NursePractitioner"
        isOpen={isNpModalOpen}
        setIsOpen={setIsNpModalOpen}
        className="flex flex-col"
        data-testid="nurse-practitioner-modal">
        <Modal.Description className="text-center">
          <div>
            <Icon className="h-10 text-center text-primary pb-8" icon="circle-info" />
          </div>
          Nurse practitioners are highly trained medical providers who can diagnose, treat and
          manage your care while working with a doctor.
        </Modal.Description>
      </Modal>

      {/* INSURANCE MODAL */}
      <ProviderInsuranceModal
        provider={provider}
        isModalOpen={isInsuranceModalOpen}
        setIsModalOpen={setIsInsuranceModalOpen}
      />
    </>
  )
}

export default ProviderCard
