'use client'
import { Button } from '@shc/ui'
import type { TypeProviderPublic } from '@lib/provider-types'
import { type MouseEvent, useState, useContext } from 'react'
import { setSchedulingType } from '@lib/utilities/provider-utilities'
import { useAppConfig } from '@components/client-wrapper'
import AppointmentByPhone from '@components/appointment-by-phone-modal'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import { BlockContext, PageContext, ProviderContext } from '@lib/analytics'

interface ProviderAppointmentButtonProps extends React.ComponentPropsWithoutRef<'a'> {
  id: string
  provider: TypeProviderPublic
  buttonType: string
}

const ProviderAppointmentButton = ({
  id,
  provider,
  buttonType,
}: ProviderAppointmentButtonProps) => {
  const configs = useAppConfig()
  const epicBookingApptUrl = configs.configs.epic.bookingApptURL
  const schedulingType = setSchedulingType(provider)
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
  let hrefValueForButton = undefined
  let phoneSchedulingModal = false
  const { track } = useAnalytics()
  const blockContext = useContext(BlockContext)
  const pageContext = useContext(PageContext)
  const providerContext = useContext(ProviderContext)

  if (schedulingType === 'Third-party' && provider?.scheduling_url) {
    hrefValueForButton = provider?.scheduling_url
  } else if (schedulingType === 'Online') {
    hrefValueForButton = `${epicBookingApptUrl}${provider.slug}`
  } else {
    phoneSchedulingModal = true
  }

  const onPressBookingAppointmentButton = (e: MouseEvent<any>) => {
    const baseContexts: SPContext[] = []
    if (pageContext) {
      baseContexts.push(pageContext)
    }
    if (blockContext) {
      baseContexts.push(blockContext)
    }
    if (providerContext) {
      baseContexts.push(providerContext)
    }
    // track button click
    track({
      event: {
        name: 'book_appt_click',
        data: {
          appointment_method: phoneSchedulingModal ? 'Call to schedule' : 'Online booking',
        },
      },
      contexts: [
        ...baseContexts,
        {
          name: 'component',
          data: {
            component_text: e.currentTarget.textContent,
          },
        },
      ],
    })

    // track open phone modal
    if (phoneSchedulingModal) {
      setIsPhoneModalOpen(true)
      track({
        event: { name: 'modal_open', data: {} },
        contexts: [
          ...baseContexts,
          {
            name: 'component',
            data: {
              component_text: e.currentTarget.textContent,
            },
          },
          { name: 'modal', data: { modal_name: 'Booking' } },
        ],
      })
    }
  }

  return (
    <>
      <Button
        id={id}
        href={hrefValueForButton}
        target={schedulingType === 'Third-party' ? '_blank' : undefined}
        rel={schedulingType === 'Third-party' ? 'noopener noreferrer' : undefined}
        aria-label="Book appointment"
        size={buttonType === 'nav' ? 'sm' : undefined}
        width={buttonType === 'nav' ? 'auto' : 'full'}
        data-testid="book-appt-button"
        className={buttonType === 'nav' ? 'invisible lg:inline-flex' : undefined}
        onClick={onPressBookingAppointmentButton}>
        Book&nbsp;appointment
      </Button>

      <AppointmentByPhone
        provider={provider}
        isModalOpen={isPhoneModalOpen}
        setIsModalOpen={setIsPhoneModalOpen}
      />
    </>
  )
}

export default ProviderAppointmentButton
